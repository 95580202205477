import axios from 'axios'
import { toast } from 'react-toastify'
import * as Actions from 'components/auth/store/actions'
import { downloadBase64File } from 'components/utils'
import { API_URL, FILE_TYPE } from 'constants/index'

export const GET_MOTIVES = '[PURCHASES AND OUTPUTS] GET MOTIVES'

// compras

export const GET_PURCHASES = '[PURCHASES] GET PURCHASES'

export const DOWNLOADING_PURCHASES_REPORT = "[PURCHASES] DOWNLOADING PURCHASES REPORT"

export const GET_PURCHASE = '[PURCHASES] GET PURCHASE'

export const CRUD_PURCHASE_HEADER = '[PURCHASES] CRUD PURCHASE HEADER'

export const CRUD_PURCHASE = '[PURCHASES] CRUD PURCHASE'

export const CRUD_PURCHASE_ITEM = '[PURCHASES] CRUD PURCHASE ITEM'

export const GET_PURCHASE_ITEMS = '[PURCHASES] GET PURCHASE ITEMS'

export const FINISH_PURCHASE_DETAIL = '[PURCHASES] FINISH PURCHASE DETAIL'

export function getPurchases(
  {
    page = 1,
    pageSize = 10,
    providerId = 0,
    startDate = '',
    endDate = '',
    paymentType = '',
    status = '',
    search = '',
    skipBranch = false,
    skipUser = false
  }
) {
  const request = axios.get(`${API_URL}/api/compra/registros`, {
    params: {
      page,
      por_pagina: pageSize,
      proveedor_id: providerId,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      tipo_pago: paymentType,
      estado: status,
      busqueda: search,
      omitir_sucursal: skipBranch,
      omitir_usuario: skipUser
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_PURCHASES, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_PURCHASES, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_PURCHASES, payload: { loading: false } })
      console.log(error)
    })
  }
}

export function downloadPurchasesReportPdf(
  {
    providerId = 0,
    startDate = '',
    endDate = '',
    paymentType = '',
    status = '',
    search = '',
  }
) {
  const request = axios.get(`${API_URL}/api/compra/pdf-reporte-compras`, {
    params: {
      proveedor_id: providerId,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      tipo_pago: paymentType,
      estado: status,
      busqueda: search,
    }
  })
  return (dispatch) => {
    dispatch({ type: DOWNLOADING_PURCHASES_REPORT, payload: true })
    request.then((response) => {
      downloadBase64File({
        filename: response.data.nombre,
        fileType: FILE_TYPE.PDF,
        file: response.data.archivo
      })
      toast.success('Se descargó el PDF.')
      dispatch({ type: DOWNLOADING_PURCHASES_REPORT, payload: false })
      return
    }).catch((error) => {
      dispatch({ type: DOWNLOADING_PURCHASES_REPORT, payload: false })
      toast.error('Algo salió mal. No se pudo descargar el PDF.')
      console.error(error)
    })
  }
}

export function getPurchase({ purchaseId }) {
  const request = axios.get(
    `${API_URL}/api/compra/obtener-compra?idcompra=${purchaseId}`
  )
  return (dispatch) => {
    dispatch({ type: GET_PURCHASE, payload: { loading: true } })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        dispatch({
          type: GET_PURCHASE,
          payload: { loading: false, data: response.data.detalles },
        })
        setTimeout(() => {
          dispatch({
            type: GET_PURCHASE,
            payload: { loading: false, data: null },
          })
        }, 50)
      })
      .catch((error) => {
        dispatch({ type: GET_PURCHASE, payload: { loading: false, data: null } })
        toast.error('Error inesperado, no se pudo obtener la compra')
        console.log(error)
      })
  }
}

export function savePurchaseHeader(form) {
  const request = axios.post(
    `${API_URL}/api/compra/guardar-cabecera`,
    form
  )
  return (dispatch) => {
    dispatch({ type: CRUD_PURCHASE_HEADER, payload: { loading: true } })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        if (response.data.status === 200) {
          dispatch({
            type: CRUD_PURCHASE_HEADER,
            payload: {
              loading: false,
              crud: true,
              data: response.data.detalles,
            },
          })
          toast.success(response.data.mensaje)
        } else {
          toast.error(response.data.mensaje)
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_PURCHASE_HEADER,
            payload: { loading: false, crud: false, data: null },
          })
        }, 50)
      })
      .catch((error) => {
        dispatch({
          type: CRUD_PURCHASE_HEADER,
          payload: { loading: false, crud: false, data: null },
        })
        toast.error('Error inesperado. No se pudo generar la compra')
        console.log(error)
      })
  }
}

export function savePurchaseItem(form) {
  const request = axios.post(
    `${API_URL}/api/compra-detalle/guardar-item`,
    form
  )
  return (dispatch) => {
    dispatch({ type: CRUD_PURCHASE_ITEM, payload: { loading: true } })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_PURCHASE_ITEM,
            payload: { loading: false, crud: true },
          })
          toast.success(response.data.mensaje)
        } else {
          toast.error(response.data.mensaje)
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_PURCHASE_ITEM,
            payload: { loading: false, crud: false },
          })
        }, 50)
      })
      .catch((error) => {
        dispatch({
          type: CRUD_PURCHASE_ITEM,
          payload: { loading: false, crud: false },
        })
        toast.error('Error inesperado. No se pudo guardar el item')
        console.log(error)
      })
  }
}

export function getPurchaseItems({ purchaseId = 0 }) {
  const request = axios.get(
    `${API_URL}/api/compra-detalle/items?idcompra=${purchaseId}`
  )
  return (dispatch) => {
    dispatch({ type: GET_PURCHASE_ITEMS, payload: { loading: true } })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }

        dispatch({
          type: GET_PURCHASE_ITEMS,
          payload: { loading: false, data: response.data.detalles },
        })

        setTimeout(() => {
          dispatch({
            type: GET_PURCHASE_ITEMS,
            payload: { loading: false, data: null },
          })
        }, 50)
      })
      .catch((error) => {
        dispatch({
          type: GET_PURCHASE_ITEMS,
          payload: { loading: false, data: null },
        })
        toast.error(
          'Error inesperado. No se pudo obtener los items de la compra'
        )
        console.log(error)
      })
  }
}

export function deletePurchaseItem(form) {
  const request = axios.post(
    `${API_URL}/api/compra-detalle/eliminar-item`,
    form
  )
  return (dispatch) => {
    dispatch({ type: CRUD_PURCHASE_ITEM, payload: { loading: true } })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_PURCHASE_ITEM,
            payload: { loading: false, crud: true },
          })
          toast.success(response.data.mensaje)
        } else {
          toast.error(response.data.mensaje)
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_PURCHASE_ITEM,
            payload: { loading: false, crud: false },
          })
        }, 50)
      })
      .catch((error) => {
        dispatch({
          type: CRUD_PURCHASE_ITEM,
          payload: { loading: false, crud: false },
        })
        toast.error('Error inesperado. No se pudo eliminar el item')
        console.log(error)
      })
  }
}

export function finishPurchaseDetail(form) {
  const request = axios.post(
    `${API_URL}/api/compra-detalle/finalizar-items`,
    form
  )
  return (dispatch) => {
    dispatch({ type: FINISH_PURCHASE_DETAIL, payload: { loading: true } })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: FINISH_PURCHASE_DETAIL,
            payload: { loading: false, crud: true },
          })
          toast.success(response.data.mensaje)
        } else {
          toast.error(response.data.mensaje)
        }
        setTimeout(() => {
          dispatch({
            type: FINISH_PURCHASE_DETAIL,
            payload: { loading: false, crud: false },
          })
        }, 50)
      })
      .catch((error) => {
        dispatch({
          type: FINISH_PURCHASE_DETAIL,
          payload: { loading: false, crud: false },
        })
        toast.error(
          'Error inesperado. No se pudo finalizar el detalle de la compra'
        )
        console.log(error)
      })
  }
}

export function deletePurchase(form) {
  const request = axios.post(
    `${API_URL}/api/compra/eliminar-compra`,
    form
  )
  return (dispatch) => {
    dispatch({ type: CRUD_PURCHASE, payload: { loading: true } })
    toast.info('Anulando compra...', {
      toastId: 'deleteOutput',
      autoClose: false,
    })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_PURCHASE,
            payload: { loading: false, crud: true },
          })
          toast.success(response.data.mensaje, {
            toastId: 'deleteOutput',
            autoClose: 5000,
          })
        } else {
          toast.error(response.data.mensaje, {
            toastId: 'deleteOutput',
            autoClose: 5000,
          })
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_PURCHASE,
            payload: { loading: false, crud: false },
          })
        }, 50)
      })
      .catch((error) => {
        dispatch({
          type: CRUD_PURCHASE,
          payload: { loading: false, crud: false },
        })
        toast.error('Error inesperado, no se pudo anular la compra', {
          toastId: 'deleteOutput',
        })
        console.log(error)
      })
  }
}

export function finishPurchase(form) {
  const request = axios.post(
    `${API_URL}/api/compra/finalizar-compra`,
    form
  )
  return (dispatch) => {
    dispatch({ type: CRUD_PURCHASE, payload: { loading: true } })
    toast.info('Finalizando compra...', {
      toastId: 'finishPurchase',
      autoClose: false,
    })
    request
      .then((response) => {
        if (parseInt(response.data.status) === 404) {
          if (localStorage.getItem('access_token')) {
            localStorage.removeItem('access_token')
            delete axios.defaults.headers.common['Authorization']
            return dispatch(Actions.logoutUser())
          }
          return
        }
        if (parseInt(response.data.status) === 200) {
          dispatch({
            type: CRUD_PURCHASE,
            payload: { loading: false, crud: true },
          })
          toast.success(response.data.mensaje, {
            toastId: 'finishPurchase',
            autoClose: 5000,
          })
        } else {
          toast.error(response.data.mensaje, {
            toastId: 'finishPurchase',
            autoClose: 5000,
          })
        }
        setTimeout(() => {
          dispatch({
            type: CRUD_PURCHASE,
            payload: { loading: false, crud: false },
          })
        }, 50)
      })
      .catch((error) => {
        dispatch({
          type: CRUD_PURCHASE,
          payload: { loading: false, crud: false },
        })
        toast.error('Error inesperado, no se pudo finalizar la compra', {
          toastId: 'finishPurchase',
        })
        console.log(error)
      })
  }
}
