/**
 * Función para descargar un archivo desde una cadena base64.
 * Convierte la cadena base64 en un archivo binario y lo descarga en el navegador.
 * 
 * @param {Object} params - Parámetros de la función.
 * @param {string} params.file - La cadena base64 del archivo.
 * @param {string} params.fileType - El tipo MIME del archivo (puedes usar las constantes de `FILE_TYPE`).
 * @param {string} params.filename - El nombre del archivo + su extención.
 * 
 * @example
 * downloadBase64File({
 *   file: 'aGVsbG8gd29ybGQ=', 
 *   fileType: FILE_TYPE.PDF, 
 *   filename: 'documento.pdf'
 * });
 */
export function downloadBase64File({ file, fileType, filename }) {
  const binaryString = atob(file);
  const bytes = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([bytes], { type: fileType });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = filename;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  URL.revokeObjectURL(url);
}
