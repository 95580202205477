/**
 * 
 * @param {any} text 
 * @returns {Promise<boolean>} 
 *  - true si se copió al portapapeles
 *  - false si falló
 */
export const copyToClipboard = async (text) => {
  if (!text) return false

  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (err) {
    console.log(err)
    return false
  }
}
