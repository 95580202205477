import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { API_URL } from 'constants/index'

export const GET_USER_ASSIGNABLE_MENUS = "[ACCESS_CONTROL] GET USER_ASSIGNABLE_MENUS"

export const CRUD_USER_MENUS = "[ACCESS_CONTROL] CRUD USER MENUS"

export function getUserAssignableMenus(userId = 0) {
  const request = axios.get(`${API_URL}/api/usuario-menu/menus-a-seleccionar/${userId}`)
  return (dispatch) => {
    dispatch({ type: GET_USER_ASSIGNABLE_MENUS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: GET_USER_ASSIGNABLE_MENUS, payload: { loading: false, data: response.data.detalles } })

    }).catch((error) => {
      dispatch({ type: GET_USER_ASSIGNABLE_MENUS, payload: { loading: false, data: null } })
      toast.error("Error al obtener los menús asignables")
      console.log(error)
    })
  }
}

export function saveUserMenus(form) {
  const request = axios.post(`${API_URL}/api/usuario-menu/guardar-menus`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_USER_MENUS, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }

      dispatch({ type: CRUD_USER_MENUS, payload: { loading: false, crud: true } })
      toast.success(response.data.mensaje)

      setTimeout(() => {
        dispatch({ type: CRUD_USER_MENUS, payload: { loading: false, crud: false } })
      }, 50)

    }).catch((error) => {
      dispatch({ type: CRUD_USER_MENUS, payload: { loading: false, crud: false } })
      toast.error("Ocurrió un error inesperado. Intente nuevamente.")
      console.log(error)
    })
  }
}
