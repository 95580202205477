/*
    Configuración: routesConfig
    Descripción: Archivo de configuración de las rutas de la aplicación
*/

import React from 'react';
import { Redirect } from 'react-router-dom';
import AppUtils from '@app/AppUtils';

import { LoginConfig } from 'components/pages/login/LoginConfig';
import { ProductsConfig } from 'components/pages/products/ProductsConfig';
import { DashboardConfig } from 'components/pages/dashboard/DashboardConfig';
import { PeopleConfig } from 'components/pages/people/PeopleConfig';
import { VouchersConfig } from 'components/pages/vouchers/VouchersConfig';
import { EstablishmentConfig } from 'components/pages/establishment/EstablishmentConfig';
import { UsersConfig } from 'components/pages/users/UsersConfig';
import { SalesConfig } from 'components/pages/sales/SalesConfig';
import { OrdersConfig } from 'components/pages/orders/OrdersConfig';
import { ProformasConfig } from 'components/pages/proformas/ProformasConfig';
import { ManageCashConfig } from 'components/pages/manage_cash/ManageCashConfig';
import { AccessControlConfig } from 'components/pages/access_control/AccessControlConfig';
import { CashReceiptsConfig } from 'components/pages/cash_receipts/CashReceiptsConfig';
import { CashOutlaysConfig } from 'components/pages/cash_outlays/CashOutlaysConfig';
import { ManageCpeConfig } from 'components/pages/manage_cpe/ManageCpeConfig';
import { CreditNotesConfig } from 'components/pages/credit_notes/CreditNotesConfig';
import { ElectronicGuidesConfig } from 'components/pages/electronic_guide/ElectronicGuidesConfig';
import { MenuControlConfig } from 'components/pages/menu_control/MenuControlConfig';
import { ManagementDriverConfig } from 'components/pages/management_driver/ManagementDriverConfig';
import { VehiclesConfig } from 'components/pages/vehicles/VehiclesConfig';
import { VehicleRequirementsConfig } from 'components/pages/vehicle_requirements/VehicleRequirementsConfig';
import { VehicleOrderServicesConfig } from 'components/pages/vehicle_service_orders/VehicleOrderServicesConfig';
import { EmployeesConfig } from 'components/pages/employees/EmployeesConfig';
import { AttentionServiceOrdersConfig } from 'components/pages/attention_service_orders/AttentionServiceOrdersConfig';
import { VehicleStationsConfig } from 'components/pages/vehicle_stations/VehicleStationsConfig';
import { ReportServiceOrdersConfig } from 'components/pages/report_service_orders/ReportServiceOrdersConfig';
import { DispatchesConfig } from 'components/pages/dispatches/DispatchesConfig';
import { EntriesConfig } from 'components/pages/entries/EntriesConfig';
import { OutputsConfig } from 'components/pages/outputs/OutputsConfig';
import { ProductPricesConfig } from 'components/pages/product_prices/ProductPricesConfig';
import { OperatingExpensesConfig } from 'components/pages/operating_expenses/OperatingExpensesConfig';
import { LoansConfig } from 'components/pages/loans/LoansConfig';
import { StoresConfig } from 'components/pages/stores/StoresConfig';
import { ComplaintsBookConfig } from 'components/pages/complaints_book/ComplaintsBookConfig';
import { EstablishmentsComplaintsConfig } from 'components/pages/establishments_complaints/EstablishmentsComplaintsConfig';
import { TransfersConfig } from 'components/pages/transfers/TransfersConfig';
import { LoansOpeningConfig } from 'components/pages/loans_opening/LoansOpeningConfig';
import { LoanOpeningIncomesConfig } from 'components/pages/loan_opening_incomes/LoanOpeningIncomesConfig';
import { LoanOpeningExpensesConfig } from 'components/pages/loan_opening_expenses/LoanOpeningExpensesConfig';
import { LoansReportConfig } from 'components/pages/loans_report/LoansReportConfig';
import { InventoryConfig } from 'components/pages/inventory/InventoryConfig';
import { RequirementsConfig } from 'components/pages/requirements/RequirementsConfig';
import { SalesAccountingReportConfig } from 'components/pages/sales_accounting_report/SalesAccountingReportConfig';
import { PurchasesConfig } from 'components/pages/purchases/PurchasesConfig';
import { CustomerCreditsConfig } from 'components/pages/customer_credits/CustomerCreditsConfig';
import { ProviderCreditsConfig } from 'components/pages/provider_credits/ProviderCreditsConfig';
import { SalesBySellerConfig } from 'components/pages/sales_by_seller/SalesBySellerConfig';
import { PurchasesReportConfig } from 'components/pages/purchases_report/PurchasesReportConfig';

const routesConfig = [
  LoginConfig,
  ProductsConfig,
  DashboardConfig,
  PeopleConfig,
  VouchersConfig,
  EstablishmentConfig,
  UsersConfig,
  SalesConfig,
  OrdersConfig,
  ProformasConfig,
  ManageCashConfig,
  AccessControlConfig,
  CashReceiptsConfig,
  CashOutlaysConfig,
  ManageCpeConfig,
  CreditNotesConfig,
  ElectronicGuidesConfig,
  MenuControlConfig,
  ManagementDriverConfig,
  VehiclesConfig,
  EmployeesConfig,
  VehicleRequirementsConfig,
  VehicleOrderServicesConfig,
  AttentionServiceOrdersConfig,
  VehicleStationsConfig,
  ReportServiceOrdersConfig,
  DispatchesConfig,
  EntriesConfig,
  OutputsConfig,
  ProductPricesConfig,
  OperatingExpensesConfig,
  LoansConfig,
  StoresConfig,
  ComplaintsBookConfig,
  EstablishmentsComplaintsConfig,
  TransfersConfig,
  LoansOpeningConfig,
  LoanOpeningIncomesConfig,
  LoanOpeningExpensesConfig,
  LoansReportConfig,
  InventoryConfig,
  RequirementsConfig,
  SalesAccountingReportConfig,
  PurchasesConfig,
  CustomerCreditsConfig,
  ProviderCreditsConfig,
  SalesBySellerConfig,
  PurchasesReportConfig,
];

const routes = [
  ...AppUtils.generateRoutesFromConfigs(routesConfig, null),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />,
  },
  {
    component: () => <Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />,
  },
];

export default routes;
