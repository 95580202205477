import axios from "axios"
import { toast } from "react-toastify"
import * as Actions from "components/auth/store/actions"
import { downloadBase64File } from 'components/utils'
import { urlPdfs, API_URL, FILE_TYPE } from 'constants/index'
import { sendSale } from "./manage_cpe.actions"

export const GET_SALES = "[SALES] GET SALES"

export const DOWNLOADING_SALES_ACCOUNTING_REPORT = "[SALES] DOWNLOADING SALES ACCOUNTING REPORT"

export const GET_SALES_BY_SELLER = "[SALES] GET SALES BY SELLER"

export const DOWNLOADING_SALES_BY_SELLER = "[SALES] DOWNLOADING SALES BY SELLER"

export const GET_SALE = "[SALES] GET SALE"

export const CRUD_SALE = "[SALES] CRUD SALE"

export const GET_PRICE_DOLLAR_SALES = "[SALES] GET PRICE DOLLAR"

export const GET_DATA_TYPE_PAYMENT = "[SALES] GET ALL TYPE PAYMENT"

export const GET_DATA_TYPE_PAYMENT_FROMS = "[SALES] GET ALL TYPE PAYMENT FROMS"

export const GET_DATA_SERIES_VOUCHER = "[SALES] GET ALL DATA SERIES BY VOUCHER"

export const GET_DATA_CORRELATIVE_SERIES_VOUCHER = "[SALES] GET DATA CORRELATIVE SERIES BY VOUCHER"

export const GET_DATA_COIN_TYPE = "[SALES] GET DATA COIN TYPE"

export function getSales({
  page = 1,
  pageSize = 10,
  startDate = '',
  endDate = '',
  voucherType = '',
  status = '',
  search = '',
  skipBranch = false,
  skipUser = false
}) {
  const request = axios.get(`${API_URL}/api/venta/registros`, {
    params: {
      page,
      por_pagina: pageSize,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      tipo_comprobante: voucherType,
      estado: status,
      busqueda: search,
      omitir_sucursal: skipBranch,
      omitir_usuario: skipUser
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_SALES, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SALES, payload: { loading: false, ...response.data } })
    }).catch((error) => {
      dispatch({ type: GET_SALES, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function downloadSalesAccountingReportPdf({
  startDate = '',
  endDate = '',
  status = '',
  search = '',
}) {
  const request = axios.get(`${API_URL}/api/venta/pdf-reporte-contable-ventas`, {
    params: {
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      busqueda: search,
    }
  })
  return (dispatch) => {
    dispatch({ type: DOWNLOADING_SALES_ACCOUNTING_REPORT, payload: true })
    request.then((response) => {
      downloadBase64File({
        filename: response.data.nombre,
        fileType: FILE_TYPE.PDF,
        file: response.data.archivo
      })
      toast.success('Se descargó el PDF.')
      dispatch({ type: DOWNLOADING_SALES_ACCOUNTING_REPORT, payload: false })
      return
    }).catch((error) => {
      dispatch({ type: DOWNLOADING_SALES_ACCOUNTING_REPORT, payload: false })
      toast.error('Algo salió mal. No se pudo descargar el PDF.')
      console.error(error)
    })
  }
}

export function getSalesBySeller({
  sellerId = 0,
  startDate = '',
  endDate = '',
  status = '',
  brandId = 0,
  search = ''
}) {
  const request = axios.get(`${API_URL}/api/venta/registros-por-vendedor`, {
    params: {
      vendedor_id: sellerId,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      marca_id: brandId,
      busqueda: search,
    }
  })
  return (dispatch) => {
    dispatch({ type: GET_SALES_BY_SELLER, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SALES_BY_SELLER, payload: { loading: false, ...response.data.detalles } })
    }).catch((error) => {
      dispatch({ type: GET_SALES_BY_SELLER, payload: { loading: false } })
      console.log(error);
    })
  }
}

export function downloadSalesBySellerPdf({
  sellerId = 0,
  startDate = '',
  endDate = '',
  status = '',
  brandId = 0,
  search = ''
}) {
  const request = axios.get(`${API_URL}/api/venta/pdf-ventas-por-vendedor`, {
    params: {
      vendedor_id: sellerId,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      marca_id: brandId,
      busqueda: search,
    }
  })
  return (dispatch) => {
    dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: true })
    request.then((response) => {
      downloadBase64File({
        filename: response.data.nombre,
        fileType: FILE_TYPE.PDF,
        file: response.data.archivo
      })
      toast.success('Se descargó el PDF.')
      dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: false })
      return
    }).catch((error) => {
      dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: false })
      toast.error('Algo salió mal. No se pudo descargar el PDF.')
      console.error(error)
    })
  }
}

export function downloadSalesBySellerExcel({ sellerId = 0, startDate = '', endDate = '', status = '', brandId = 0, search = '' }) {
  const request = axios.get(`${API_URL}/api/venta/excel-ventas-por-vendedor`, {
    params: {
      vendedor_id: sellerId,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      marca_id: brandId,
      busqueda: search,
    }
  })
  return (dispatch) => {
    dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: true })
    request.then((response) => {
      downloadBase64File({
        filename: response.data.nombre,
        fileType: FILE_TYPE.EXCEL,
        file: response.data.archivo
      })
      toast.success('Se descargó el PDF.')
      dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: false })
      return
    }).catch((error) => {
      dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: false })
      toast.error('Algo salió mal. No se pudo descargar el PDF.')
      console.error(error)
    })
  }
}

export function downloadItemsBySellerPdf({ sellerId = 0, startDate = '', endDate = '', status = '', brandId = 0, search = '' }) {
  const request = axios.get(`${API_URL}/api/venta/pdf-items-por-vendedor`, {
    params: {
      vendedor_id: sellerId,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      marca_id: brandId,
      busqueda: search,
    }
  })
  return (dispatch) => {
    dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: true })
    request.then((response) => {
      downloadBase64File({
        filename: response.data.nombre,
        fileType: FILE_TYPE.PDF,
        file: response.data.archivo
      })
      toast.success('Se descargó el PDF.')
      dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: false })
      return
    }).catch((error) => {
      dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: false })
      toast.error('Algo salió mal. No se pudo descargar el PDF.')
      console.error(error)
    })
  }
}

export function downloadItemsBySellerExcel({ sellerId = 0, startDate = '', endDate = '', status = '', brandId = 0, search = '' }) {
  const request = axios.get(`${API_URL}/api/venta/excel-items-por-vendedor`, {
    params: {
      vendedor_id: sellerId,
      fecha_inicio: startDate,
      fecha_fin: endDate,
      estado: status,
      marca_id: brandId,
      busqueda: search,
    }
  })
  return (dispatch) => {
    dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: true })
    request.then((response) => {
      downloadBase64File({
        filename: response.data.nombre,
        fileType: FILE_TYPE.EXCEL,
        file: response.data.archivo
      })
      toast.success('Se descargó el PDF.')
      dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: false })
      return
    }).catch((error) => {
      dispatch({ type: DOWNLOADING_SALES_BY_SELLER, payload: false })
      toast.error('Algo salió mal. No se pudo descargar el PDF.')
      console.error(error)
    })
  }
}

export function getSale({ saleId }) {
  const request = axios.get(`${API_URL}/api/venta/obtener-venta?idventa=${saleId}`)
  return (dispatch) => {
    dispatch({ type: GET_SALE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({ type: GET_SALE, payload: { loading: false, data: response.data.detalles } })
      setTimeout(() => {
        dispatch({ type: GET_SALE, payload: { loading: false, data: null } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: GET_SALE, payload: { loading: false, data: null } })
      toast.error("Error inesperado, no se pudo obtener la venta")
      console.log(error)
    })
  }
}

export function saveSale(form) {
  const request = axios.post(`${API_URL}/api/venta/registrar-venta`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SALE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        sendSale({ idventa: response.data.detalles.facturacion_id, refresh: false })

        dispatch({ type: CRUD_SALE, payload: { crud: true, loading: false } })

        toast.success(response.data.mensaje, { toastId: 'saveSale', autoClose: 5000 })

        if (!!response.data.finalizado && response.data.detalles.aplica_despacho == 0) {
          window.open(`${urlPdfs.ventaTicket}/${response.data.detalles.facturacion_id}`);
        }

      } else {
        toast.error(response.data.mensaje, { toastId: "saveSale", autoClose: 5000 })
      }

      setTimeout(() => {
        dispatch({ type: CRUD_SALE, payload: { crud: false, loading: false } })
      }, 50)

    }).catch((error) => {
      dispatch({ type: CRUD_SALE, payload: { crud: false, loading: false } })
      toast.error("Error inesperado. No se pudo registrar la venta", { toastId: 'saveSale', autoClose: 5000 })
      console.log(CRUD_SALE, error)
    })
  }
}

export function updateSale(form) {
  const request = axios.post(`${API_URL}/api/venta/actualizar-venta`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SALE, payload: { loading: true } })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (response.data.status === 200) {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: true, } })
        toast.success(response.data.mensaje)
      } else {
        toast.error(response.data.mensaje)
      }
      setTimeout(() => {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado. No se pudo atualizar la venta")
      console.log(error)
    })
  }
}

export function finishSale(form) {
  const request = axios.post(`${API_URL}/api/venta/finalizar-venta`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SALE, payload: { loading: true } })
    toast.info('Finalizando venta...', { toastId: 'finishSale', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: true } })
        window.open(`${urlPdfs.ventaTicket}/${response.data.detalles}`)
        toast.success(response.data.mensaje, { toastId: 'finishSale', autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: 'finishSale', autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo finalizar la venta", { toastId: 'finishSale' })
      console.log(error)
    })
  }
}

export function deleteSale(form) {
  const request = axios.post(`${API_URL}/api/venta/anular-venta`, form)
  return (dispatch) => {
    dispatch({ type: CRUD_SALE, payload: { loading: true } })
    toast.info('Anulando venta...', { toastId: 'deleteSale', autoClose: false })
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      if (parseInt(response.data.status) === 200) {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: true } })
        toast.success(response.data.mensaje, { toastId: 'deleteSale', autoClose: 5000 })
      } else {
        toast.error(response.data.mensaje, { toastId: 'deleteSale', autoClose: 5000 })
      }
      setTimeout(() => {
        dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      }, 50)
    }).catch((error) => {
      dispatch({ type: CRUD_SALE, payload: { loading: false, crud: false } })
      toast.error("Error inesperado, no se pudo anular la venta", { toastId: 'deleteSale' })
      console.log(error)
    })
  }
}

export function getPriceDollar() {
  const request = axios.get(`${API_URL}/api/preciodollar`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_PRICE_DOLLAR_SALES,
        payload: response.data.detalles,
      })
    })
}

export function getCoinTypes() {
  const request = axios.get(`${API_URL}/api/tipomoneda`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_COIN_TYPE,
        payload: response.data.detalles,
      })
    })
}

export function getCorrelativeSeriesVoucher(form) {
  const request = axios.get(
    `${API_URL}/api/seriexcomprobantexusuario/${form.idUser}/${form.idVoucher}/${form.idSerie}`
  )
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      dispatch({
        type: GET_DATA_CORRELATIVE_SERIES_VOUCHER,
        payload: response.data.detalles,
      })
      setTimeout(() => {
        dispatch({ type: GET_DATA_CORRELATIVE_SERIES_VOUCHER, payload: {} })
      }, 50)
    })
}

export function getAllSeriesVoucher(form) {
  const request = axios.get(`${API_URL}/api/seriexcomprobante/${form.idUser}/${form.idVoucher}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {

          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_SERIES_VOUCHER,
        payload: response.data.detalles,
      })
    })
}

export function getAllTypesPayments() {
  const request = axios.get(`${API_URL}/api/tipopago`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_TYPE_PAYMENT,
        payload: response.data.detalles,
      })
    })
}

export function getPaymentForms(form) {
  const request = axios.get(`${API_URL}/api/tipoformapago/${form}`)
  return (dispatch) =>
    request.then((response) => {
      if (parseInt(response.data.status) === 404) {
        if (localStorage.getItem("access_token")) {
          localStorage.removeItem("access_token")
          delete axios.defaults.headers.common["Authorization"]
          return dispatch(Actions.logoutUser())
        }
        return
      }
      return dispatch({
        type: GET_DATA_TYPE_PAYMENT_FROMS,
        payload: response.data.detalles,
      })
    })
}