
export const userTypes = [
  {
    value: 1,
    label: 'Usuario'
  },
  {
    value: 2,
    label: 'Empleado'
  }
]

export const USER_ROLE = {
  SUPER_ADMIN: 'super-admin',
  ADMIN: 'admin',
  CASHIER: 'atm',
  SELLER: 'seller',
  TECH: 'tech',
  ACCOUNTANT: 'accountant',
  CLAIMER: 'claimer',
  ADMIN_COMPLAINTS: 'admin-complaints',
}

export const userRoles = [
  {
    value: USER_ROLE.SUPER_ADMIN,
    label: 'Super-Administrador'
  },
  {
    value: USER_ROLE.ADMIN,
    label: 'Administrador'
  },
  {
    value: USER_ROLE.CASHIER,
    label: 'Cajero'
  },
  {
    value: USER_ROLE.SELLER,
    label: 'Vendedor'
  },
  {
    value: USER_ROLE.TECH,
    label: 'Técnico'
  },
  {
    value: USER_ROLE.ACCOUNTANT,
    label: 'Contador',
  },
  {
    value: USER_ROLE.CLAIMER,
    label: 'Reclamos'
  },
  {
    value: USER_ROLE.ADMIN_COMPLAINTS,
    label: 'Administrador-Reclamos'
  }
]
