import React from 'react'
import { AuthRoles } from "components/auth"

export const SalesAccountingReportConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/sales_accounting_report`,
      component: React.lazy(() => import('./SalesAccountingReport'))
    }
  ]
}