import * as Actions from "../../actions/app"

const initialState = {

  loading_user_assignable_menus: false,
  user_assignable_menus: null,

  loading_crud_user_menus: false,
  crud_user_menus: false,

}

const accessControlReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_USER_ASSIGNABLE_MENUS: {
      return {
        ...state,
        loading_user_assignable_menus: action.payload.loading,
        user_assignable_menus: action.payload.data,
      }
    }

    case Actions.CRUD_USER_MENUS: {
      return {
        ...state,
        loading_crud_user_menus: action.payload.loading,
        crud_user_menus: action.payload.crud,
      }
    }

    default: {
      return state
    }
  }
}

export default accessControlReducer
