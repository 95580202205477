import * as Actions from "../../actions/app"

const initialState = {
  types_payments: [],
  payment_forms: [],
  series: [],
  correlativo: {},
  coin_types: [],
  price_dollar: null,

  loading_sales: false,
  sales: null,
  total_sales: 0,

  downloading_sales_accounting_report: false,

  loading_sales_by_seller: false,
  sales_headers_by_seller: null,
  sales_items_by_seller: null,
  quantity_sales_headers_by_seller: 0,
  quantity_sales_items_by_seller: 0,
  amount_sales_headers_by_seller: 0,
  amount_sales_items_by_seller: 0,

  downloading_sales_by_seller: false,

  loading_crud_sale: false,
  crud_sale: false,

  loading_sale: false,
  sale: null,

  persons_credits: null,
  credits_by_person: [],
  sale_credit_true: null,
  saved_credit: false,

  saved_pay_of_credit: false,

  payments_person_of_credit: [],
  loading_pp_of_credit: false,

  is_cancel_pay_of_credit: false,
  is_cancel_credit: false
}

const salesReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_SALES: {
      return {
        ...state,
        loading_sales: action.payload.loading,
        sales: action.payload.detalles ?? state.sales,
        total_sales: action.payload.totalRegistros ?? state.total_sales,
      }
    }

    case Actions.DOWNLOADING_SALES_ACCOUNTING_REPORT: {
      return {
        ...state,
        downloading_sales_accounting_report: action.payload,
      }
    }

    case Actions.GET_SALES_BY_SELLER: {
      return {
        ...state,
        loading_sales_by_seller: action.payload.loading,
        sales_headers_by_seller: action.payload.ventas ?? state.sales_headers_by_seller,
        sales_items_by_seller: action.payload.items ?? state.sales_items_by_seller,
        quantity_sales_headers_by_seller: action.payload.cantidad_ventas ?? state.quantity_sales_headers_by_seller,
        quantity_sales_items_by_seller: action.payload.cantidad_items ?? state.quantity_sales_items_by_seller,
        amount_sales_headers_by_seller: action.payload.total_ventas ?? state.amount_sales_headers_by_seller,
        amount_sales_items_by_seller: action.payload.total_items ?? state.amount_sales_items_by_seller,
      }
    }

    case Actions.DOWNLOADING_SALES_BY_SELLER: {
      return {
        ...state,
        downloading_sales_by_seller: action.payload,
      }
    }

    case Actions.CRUD_SALE: {
      return {
        ...state,
        loading_crud_sale: action.payload.loading,
        crud_sale: action.payload.crud,
      }
    }

    case Actions.GET_SALE: {
      return {
        ...state,
        loading_sale: action.payload.loading,
        sale: action.payload.data,
      }
    }

    case Actions.GET_PRICE_DOLLAR_SALES: {
      return {
        ...state,
        price_dollar: action.payload,
      }
    }

    case Actions.GET_DATA_COIN_TYPE: {
      return {
        ...state,
        coin_types: action.payload,
      }
    }

    case Actions.GET_DATA_CORRELATIVE_SERIES_VOUCHER: {
      return {
        ...state,
        correlativo: action.payload[0],
      }
    }

    case Actions.GET_DATA_SERIES_VOUCHER: {
      return {
        ...state,
        series: action.payload
      }
    }

    case Actions.GET_DATA_TYPE_PAYMENT: {
      return {
        ...state,
        types_payments: action.payload
      }
    }

    case Actions.GET_DATA_TYPE_PAYMENT_FROMS: {
      return {
        ...state,
        payment_forms: action.payload
      }
    }

    default: {
      return state
    }
  }
}
export default salesReducer
