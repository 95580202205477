import React from 'react'
import { AuthRoles } from "components/auth"

export const PurchasesReportConfig = {
  auth: AuthRoles.admin,
  routes: [
    {
      path: `${process.env.PUBLIC_URL}/purchases_report`,
      component: React.lazy(() => import('./PurchasesReport'))
    }
  ]
}